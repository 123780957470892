<template>
  <div class="account-wrap">
    <div class="dp-flex justify-btw">
      <nav-menu :data="navData">
        <template v-slot:content="{ item }">
          <div v-if="item.name == 'f'">
            <img
              style="width:20px;height:20px;"
              src="https://app.sinoclick.com/static/media/fb.d03076a0.svg"
            />
          </div>
        </template>
      </nav-menu>
      <div class="dp-flex align-item-c" style="margin-right:30px;">
        <div class="mr-10">
          <p class="no-wrap" @click="handleSyncAdAccount">
            <i
              class="el-icon-refresh-left f-16 color-theme cursor-pointer mr-10"
            ></i
            ><span class="title-top">同步账号状态</span>
          </p>
          <p class="no-wrap small-title">{{ version_time }}</p>
        </div>
        <el-input
          size="small"
          placeholder="广告账户名称 / ID"
          v-model="searchIpt"
        >
          <i
            @click="handleSearch(searchIpt)"
            slot="suffix"
            class="el-icon-search"
          ></i>
        </el-input>
      </div>
    </div>
    <ul class="list-wrap dp-flex flex-w">
      <li
        @click="handleSelectAccount({ item, index })"
        class="dp-flex align-item-c"
        v-for="(item, index) in adAccounts"
        :key="index"
        :label="item.id"
      >
        <div class="align-self-start pr source dp-flex align-item-c justify-c">
          <img
            style="width:15px;height:15px;"
            src="https://app.sinoclick.com/static/media/fb.d03076a0.svg"
          />
          <p
            :class="[
              'small-circle',
              'pa',
              item.accountStatus == 1 ? 'bg-success' : 'bg-danger'
            ]"
          ></p>
        </div>
        <div>
          <p class="title-top">{{ item.name }}</p>
          <p class="small-title">ID：{{ item.id }}</p>
        </div>
      </li>
    </ul>
    <!-- 其它帐号设置预览弹窗 -->
    <other-account-set-preview
      :visible="setPreviewVisible"
      :data="resInitData"
      @close-dialog="setPreviewVisible = false"
      :deriveRuleData="deriveRuleData"
      :deriveUrlData="deriveUrlData"
      :cusAdSetData="cusAdSetData"
      @confirm-quote-other-account="confirmQuoteOtherAccount"
    ></other-account-set-preview>
  </div>
</template>
<script>
import navMenu from "@/views/components/navMenu";
import otherAccountSetPreview from "@/views/adAssets/components/otherAccountSetPreview";
import { getSetsByAccounts, getAdAccouts, synBM } from "@/api/adAssets";
export default {
  components: {
    navMenu,
    otherAccountSetPreview
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    // 引用其他广告账号设置预览数据
    deriveRuleData: {
      type: Array,
      default: () => []
    },
    deriveUrlData: {
      type: Array,
      default: () => []
    },
    cusAdSetData: {
      type: Array,
      default: () => []
    },
    createUrlFn: {
      type: Function
    }
  },
  data() {
    return {
      // 导航
      navData: {
        navMenu: [
          // { id: "1", name: "全部", disabled: true },
          { id: "2", name: "f" }
        ],
        activeNavIndex: "2",
        bgc: "",
        mode: "horizontal",
        customClass: ""
      },
      // 搜索
      searchIpt: "",
      adAccounts: [],
      // 选择某帐号得到的初始规则数据
      resInitData: {},
      setPreviewVisible: false,
      version_time: "",
      total: ""
    };
  },
  methods: {
    // 广告同步
    // synBM() {
    //   this.$showLoading();
    //   synBM({ type: "account" }).then(res => {
    //     this.$hideLoading();
    //     if (res.code == 200) {
    //       // this.$message.success(`同步成功！`);
    //       this.$message.success(res.comment);
    //       this.getAdAccouts();
    //     }
    //   });
    // },
    // 获取广告账户列表
    getAdAccouts() {
      let params = {
        keyword: this.searchIpt,
        limit: 10000,
        page: 1,
        id: this.$store.state.num.selectedNum
      };
      getAdAccouts(params).then(res => {
        if (res.code == 200) {
          if (res.data.list) {
            let arr = JSON.parse(JSON.stringify(res.data.list));
            this.adAccounts = arr;
            this.version_time = res.data.version_time;
            this.total = res.data.total;
          }
        }
      });
    },
    confirmQuoteOtherAccount() {
      this.$emit("confirm-quote-other-account", this.resInitData);
    },
    // 同步广告帐号
    handleSyncAdAccount() {
      // this.synBM();
      this.$bus.$emit("trigger-synBM");
    },
    // 搜索
    handleSearch(ipt) {
      this.getAdAccouts();
    },
    // 引用其它帐号
    handleSelectAccount({ item, index }) {
      this.getSetsByAccounts(item.id);
      this.setPreviewVisible = true;
    },
    // 根据广告账号获取偏好设置数据-回显
    getSetsByAccounts(id) {
      this.$showLoading();
      let params = { accounts: [id], limit: 10, page: 1 };
      getSetsByAccounts(params).then(res => {
        this.$hideLoading();
        if (res.code == 0) {
          if (res.data.list[0]) {
            this.resInitData = JSON.parse(JSON.stringify(res.data.list[0]));
            this.resInitData.lastUrl = this.createUrlFn(
              res.data.list[0].gg_analytics,
              res.data.list[0].url_tags
            );
          }
        }
      });
    }
  },
  created() {
    // 获取广告账户列表
    this.getAdAccouts();
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.account-wrap {
  .el-menu--horizontal > .el-menu-item {
    height: 44px;
    line-height: 44px;
    @extend .px-10;
  }
  .title-top {
    color: $theme-color;
  }
  .small-title {
    @extend .f-12;
    color: rgb(156, 158, 164);
  }
  .el-input__suffix {
    margin-right: 5px;
    @extend .dp-flex;
    @extend .align-item-c;
  }
  .list-wrap {
    max-height: 300px;
    overflow: auto;
    li {
      width: 50%;
      height: 48px;
      &:hover {
        background: rgb(245, 245, 247);
      }
    }
    .small-circle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      top: 8px;
      left: 20px;
    }
    .source {
      width: 32px;
      height: 32px;
    }
  }
  .no-wrap {
    white-space: nowrap;
  }
}
</style>