<template>
  <el-dialog
    custom-class="set-preview-dialog common-dialog"
    :visible="visible"
    title="引用广告账号设置"
    @close="handleClose"
    append-to-body
  >
    <p class="mb-20">
      <span class="mr-10">{{ data.account_name }}</span>
      <span>{{ data.account_id }}</span>
    </p>
    <div class="item-list" v-for="(item, index) in cusListData" :key="index">
      <template
        v-if="
          $route.query.mark == 'all' ||
            ($route.query.mark == 'name' && index == 0) ||
            ($route.query.mark == 'trace' && index == 1) ||
            ($route.query.mark == 'ad' && index == 2)
        "
      >
        <h4 class="mb-20">{{ item.title }}</h4>
        <ul class="pl-20">
          <li class="mb-30" v-for="(opt, opti) in item.options" :key="opti">
            <p>
              {{ opt.label + "原值："
              }}{{
                opt.prop == "split_type"
                  ? cusAdSetData &&
                    cusAdSetData.length &&
                    cusAdSetData.find(c => c.id == data[opt.prop]) &&
                    cusAdSetData.find(c => c.id == data[opt.prop]).label
                  : data[opt.prop]
              }}
            </p>
          </li>
        </ul>
      </template>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">取消</el-button>
      <el-button size="small" type="primary" @click="handleConfirm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    data: {
      type: Object,
      default: () => {}
    },
    // 引用其他广告账号设置预览数据
    deriveRuleData: {
      type: Array,
      default: () => []
    },
    deriveUrlData: {
      type: Array,
      default: () => []
    },
    cusAdSetData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    cusListData() {
      let arr1 = ["deriveRuleData", "deriveUrlData", "cusAdSetData"],
        arr2 = ["命名规则", "转化追踪", "广告结构设置"],
        arr = [];
      arr1.forEach((d, i) => {
        let obj = {};
        if (i < 2) {
          if (i == 1) {
            this[d][1].prop = "lastUrl";
          }
          obj = {
            title: arr2[i],
            options: this[d]
          };
        } else {
          obj = {
            title: arr2[i],
            options: [{ label: arr2[i], prop: "split_type" }]
          };
        }
        arr.push(obj);
      });
      return arr;
    }
  },
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$emit("close-dialog");
    },
    handleConfirm() {
      this.$emit("confirm-quote-other-account");
      this.$emit("close-dialog");
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.set-preview-dialog {
  width: 600px;
  .el-dialog__header {
    @extend .dp-flex;
  }
  .el-dialog__title {
    @extend .text-a-c;
  }
  .el-dialog__body {
    background-color: rgb(245, 245, 247);
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  .pl-20 {
    padding-left: 20px;
  }
}
</style>